import axios from 'axios'

import moment from 'moment'
import 'moment/locale/it'
moment.locale('it')

export const createTransferOrder = ({ transferOrder, token }) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/transfer-order`,
    data: transferOrder,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const sendEmail = ({
  transferOrders,
  emailSubject,
  emailBody,
  token,
  cc,
}) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/email/send`,
    data: {
      transferOrders: transferOrders.sort((a, b) =>
        Math.sign(a.warehouseId - b.warehouseId),
      ),
      emailBody,
      emailSubject,
      cc,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// const createTransferOrders = ({ transferOrders, token }) => {
//   return Promise.all(
//     transferOrders.map(transferOrder =>
//       createTransferOrder({ ...transferOrder, token }),
//     ),
//   )
// }

const getDeliveryDate = when => {
  if (moment().isoWeekday() > when) {
    return moment()
      .isoWeekday(when + 7)
      .format('D MMMM YYYY')
  }

  return moment().isoWeekday(when).format('D MMMM YYYY')
}

export const getEmailFields = ({
  transferOrders,
  storeName,
  when,
  time,
  cc,
  shippingCurrier,
  to,
  isFromStore,
  storeFromName,
  pickUpDay,
  pickUpTime,
  apparelAdditionalCare,
}) => {
  const pickUpText = pickUpDay
    ? `Ritiro ${pickUpTime ? `${pickUpTime} del ` : ''}${getDeliveryDate(
        pickUpDay,
      )}.\n`
    : ''
  const deliveryTimeText = time ? `${time} ` : ''
  const deliveryDateText = when ? `del ${getDeliveryDate(when)} ` : ''
  return transferOrders.length
    ? {
        to: to ? to : process.env.REACT_APP_TRANSFER_ORDER_MAIL_TO,
        cc: `${process.env.REACT_APP_TRANSFER_ORDER_MAIL_CC}${
          process.env.NODE_ENV === 'production' ? `,${cc}` : ''
        }`,
        subject: `${
          isFromStore ? `Trasferimento` : `Rifornimento`
        } ${storeName} ${moment().format('DD/MM/YYYY')}`,
        body: `Ciao ${isFromStore ? storeFromName : 'Daniela'},

${transferOrders
  .map(
    ({ id, warehouseName }) =>
      `L'ordine ${id} ${
        isFromStore
          ? `è per il ${storeName}`
          : `è per prodotti ${warehouseName}`
      }`,
  )
  .join('\n')}

${
  isFromStore
    ? `
Il ritiro è previsto per domani, @CS: ci potresti mandare la lettera di vettura?`
    : `${pickUpText}Consegna ${deliveryTimeText}${deliveryDateText}con ${shippingCurrier}.`
}
${apparelAdditionalCare ? apparelAdditionalCare : ''}
${
  !isFromStore
    ? `
In aggiunta, servirebbero:
  [INSERIRE AGGIUNTE QUI]
`
    : ''
}
Grazie`,
      }
    : {}
}
